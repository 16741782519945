import React, { useEffect, useState } from "react";
import { IconButton, Badge } from "@material-ui/core";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import { useSelector, useDispatch } from "react-redux";
import { CartDrawer } from "./CartDrawer";
import { useRouteMatch, useHistory } from "react-router-dom";
import { numberWithCommas } from "../../../utils/numbersWithComma";
import BackspaceIcon from "@material-ui/icons/Backspace";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Icon } from "@mui/material";
import Box from "@mui/material/Box";
import { ReceiptLongOutlined } from "@mui/icons-material";
import { setTotalPrice } from "../../../redux/cartReducer/actions";
import { is } from "date-fns/locale";

const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;
const colorBackground = process.env.REACT_APP_BACKGROUND_COLOR;

const $elementHide = {
  visibility: "hidden",
  opacity: 0,
  width: 0,
  height: 0,
  overflow: "hidden", // Ensure no inner content spills out
};

export const Cart = (props) => {
  const { isHidden } = props;
  const match = useRouteMatch();
  const history = useHistory();
  const { cartItems } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.public);
  const dispatch = useDispatch();
  const [totalItems, setTotalItems] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    const checkCart = localStorage.getItem(`ajsliders_cart`);
    if (checkCart) {
      dispatch({ type: "SET_CART", payload: JSON.parse(checkCart) });
    } else {
      dispatch({ type: "SET_CART", payload: [] });
    }
  }, []);

  useEffect(() => {
    if (cartItems.length) {
      localStorage.setItem(`ajsliders_cart`, JSON.stringify(cartItems));
      let total = 0;
      let totalPrice = 0;
      cartItems.forEach((item) => {
        let mod_price = 0;
        item.modifiers.forEach((mod) => (mod_price += mod.DefaultPrice));
        total += item.quantity;
        totalPrice +=
          item.quantity * (item.DefaultPrice / 100 + mod_price / 100);
      });
      dispatch(setTotalPrice(totalPrice));
      setTotalItems(total);
    } else {
      setTotalItems(0);
      dispatch(setTotalPrice(0));
      localStorage.removeItem(`ajsliders_cart`);
    }
  }, [cartItems]);

  return (
    <React.Fragment style={isHidden === 1 ? { $elementHide } : null}>
      <div style={isHidden === 1 ? { $elementHide } : null}>
        <Box
          sx={{
            marginY: enableKiosk === "0" ? 2 : 0.5,
            marginLeft: 2,
          }}
        >
          <IconButton
            onClick={
              () =>
                //user ?
                history.push(`/checkout`)
              // : history.push("/sign-in", {
              //   restaurantId: match.params.restaurantId,
              // })
            }
            style={
              isHidden === 1
                ? { opacity: 0 }
                : { backgroundColor: "#6D8F18", borderRadius: "5px" }
            }
          >
            {enableKiosk === "0" ? (
              <Badge
                color="secondary"
                badgeContent={totalItems}
                style={{
                  color: "white",
                }}
              >
                <ReceiptLongOutlined
                  fontSize="large"
                  color="inherit"
                  style={{
                    width: "1.15em",
                    height: "1.15em",
                  }}
                />
              </Badge>
            ) : (
              <Badge
                color="secondary"
                badgeContent={totalItems}
                style={{
                  color: "black",
                  transform: "scale(1.25)",
                }}
              >
                <ReceiptLongOutlined
                  style={{
                    transform: "scale(1.15)",
                    width: "2em",
                    height: "2em",
                    padding: "9px",
                  }}
                />
              </Badge>
            )}
          </IconButton>
        </Box>
        <CartDrawer open={openDrawer} setOpen={setOpenDrawer} />
      </div>
    </React.Fragment>
  );
};
