import { Container, Row, Col, Button, h5, p, h2, div } from "react-bootstrap";
import { MenuNavigation } from "../components/MenuNavigation";
import { Footer } from "../components/Footer";
import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const buttonText = process.env.REACT_APP_BUTTON_TEXT;
const textHighlight = process.env.REACT_APP_TEXT_HIGHLIGHT;

export const TermsandCondition = () => {
  return (
    <div>
      <MenuNavigation />
      <div
        className="HeaderImg"
        style={{
          backgroundImage: `url(${siteMedSrc}background.png)`,
          color: buttonText,
        }}
      >
        <HelmetProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{"Data Usage: Your Information, Our Purpose"}</title>
            <link rel="canonical" href="/terms-and-conditions" />
            <meta
              name="description"
              content="Any of the information we collect from you may be used for one or more purposes."
            />
          </Helmet>
        </HelmetProvider>

        <Container>
          <Row>
            <col className="whitespace"></col>
            <Col xs={12}>
              <h1 className="center" style={{ color: textHighlight }}>
                Terms and Conditions
              </h1>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="privacy whitespace">
        <Row className="left">
          <Col xs={12}>
            <h2>What do we use your information for?</h2>
            <p>
              Any of the information we collect from you may be used for one or
              more of the following purposes:
            </p>

            <ol className="paddingSpecial">
              <li>
                To personalize your experience (the information will help{" "}
                {restaurantName} better respond to your individual needs);
              </li>

              <li>
                To enable you to control the user experience towards End Users
                and enable the Service to automatically apply the End User’s
                consent to other websites of yours;
              </li>
            </ol>

            <h3>
              To improve our website ({restaurantName} continually strives to
              improve our website offerings based on the information and
              feedback we receive from our customers);
            </h3>
            <ol className="paddingSpecial">
              <li>To identify you as a contracting party;</li>

              <li>To establish a primary channel of communication with you;</li>

              <li>
                To enable {restaurantName} to issue valid VAT invoices and to
                process transactions (your information will not be sold,
                exchanged, transferred, or given to any other company for any
                reason whatsoever, without your consent, other than for the
                express purpose of delivering the service requested);
              </li>
              <li>To enable automated handling of the subscriptions;</li>

              <li>
                To produce and display cookie declarations to End Users and
                store and display scan report(s) to you;
              </li>

              <li>
                To provide you with aggregated information on the choices of the
                End Users regarding accepted cookie types and generate a
                graphical representation in the Service Manager; and/or
              </li>

              <li>
                To send periodic e-mails (The e-mail address you provide for
                order processing, may be used to send you information and
                updates pertaining to your order, in addition to receiving
                occasional company news (if accepted), updates, related product
                or service information, etc.)
              </li>
            </ol>
            <h2>Legal basis</h2>
            <h3>1. EU General Data Protection Regulation (GDPR)</h3>
            <p>
              The processing of your data is either based on your consent or in
              case the processing is necessary for the performance of a contract
              to which you are a party, or in order to take steps at your
              request prior to entering into a contract.
            </p>

            <p>
              If the processing is based on your consent, you may at any time
              withdraw your consent by contacting us using the contact
              information.
            </p>

            <p>
              In order to enter into a contract regarding the purchase of{" "}
              {restaurantName} Service, you must provide us with the required
              personal data. If you do not want to provide us with all the
              required information, it will not be possible to deliver the
              Service.
            </p>

            <h3>2. California Online Privacy Protection Act Compliance</h3>
            <p>
              Because {restaurantName} values your privacy we have taken the
              necessary precautions to be in compliance with the California
              Online Privacy Protection Act. We therefore will not distribute
              any personal information to outside parties without your consent
              except as stated in clause (Do we disclose any information to
              outside parties?).
            </p>

            <p>
              As part of the California Online Privacy Protection Act, all users
              of our website may make any changes to their information at any
              time by logging into their account and navigating to the “profile
              page”.
            </p>

            <h3>3. Children’s Online Privacy Protection Act Compliance</h3>
            <p>
              {restaurantName} is in compliance with the requirements of the
              Children’s Online Privacy Protection Act. We will not
              intentionally collect any information from anyone under 13 years
              of age. Our website, products and services are all directed at
              people who are at least 13 years old or older.
            </p>

            <h3>4. How do we protect your information?</h3>
            <p>
              {restaurantName} implements the following technical, physical and
              organizational measures to maintain the safety of your personal
              data against accidental or unlawful destruction or accidental
              loss, alteration, unauthorized use, unauthorized modification,
              disclosure or access and against all other unlawful forms of
              processing.
            </p>

            <h3>4.1. Availability</h3>
            <p>
              The Service utilizes the extensive features of the cloud
              environment to ensure high availability, like full redundancy,
              load balancing, automatic capacity scaling, continuous data backup
              and geo-replication along with a traffic manager for automatic
              geographical failover on datacenter level disasters. All failover
              mechanisms are fully automated.
            </p>

            <p>
              No personal data is stored permanently outside {restaurantName}
              cloud platforms. The physical security is thereby maintained by
              {restaurantName} subcontractors. Microsoft’s datacenters comply
              with industry standards such as ISO 27001 for physical security
              and availability, e.g. by using security staff around the clock,
              two-factor access control using biometric and card readers,
              barriers, fencing, security cameras and other measures.
            </p>

            <h3>4.2. Integrity</h3>
            <p>
              To ensure integrity, all data transits are encrypted to align with
              best practices for protecting confidentiality and data integrity.
              E.g. all supplied credit card information is transmitted via
              Secure Socket Layer (SSL) technology and then encrypted into our
              payment gateway provider’s database only to be accessible by those
              who are authorized to access such systems and who are required to
              keep the information confidential.
            </p>

            <p>
              For data in transit, the Service uses industry-standard transport
              protocols between devices and Microsoft datacenters and within
              datacenters themselves.
            </p>

            <h3>4.3. Confidentiality</h3>
            <p>
              All personnel are subject to full confidentiality and any
              subcontractors and subprocessors are required to sign a
              confidentiality agreement if not full confidentiality is part of
              the main agreement between the parties.
            </p>

            <p>
              Whenever personal data is accessed by authorized personnel the
              access is only possible over an encrypted connection. When
              accessing the data in a database, the IP number of the person
              accessing the data must also be pre-authorized to obtain access.
            </p>

            <p>
              Any device being used to access personal data is login protected
              by {restaurantName} Azure Active Directory (AAD), Microsoft’s
              cloud based identity and access management service, and has{" "}
              {restaurantName} corporate antivirus solution installed. If any
              personal data are temporarily stored on a device, the storage unit
              on the device must also be strongly encrypted.
            </p>

            <p>
              On premise devices storing personal data temporarily is at all
              times, except when not being actively used or relocated under
              uninterrupted supervision, locked in a safe. Personal data are
              never stored on mobile media like USB sticks and DVD’s.
            </p>

            <h3>4.4. Transparency</h3>
            <p>
              {restaurantName} will at all times keep you informed about changes
              to the processes to protect data privacy and security, including
              practices and policies. You may at any time request information on
              where and how data is stored, secured and used.
              {restaurantName} will also provide the summaries of any
              independent audits of the Service.
            </p>

            <h3>4.5. Isolation</h3>
            <p>
              All access to personal data is blocked by default, using a zero
              privileges policy. Access to personal data is restricted to
              individually authorized personnel. {restaurantName} Security and
              Privacy Officer issues authorizations and maintains a log of
              granted authorizations. Authorized personnel are granted a minimum
              access on a need-to-have basis through our AAD.
            </p>

            <h3>4.6. The ability to intervene</h3>
            <p>
              {restaurantName} enables your rights of access, rectification,
              erasure, blocking and objection mainly by providing built-in
              functions for data handling in the Service Manager, by offering
              the option to send instructions through {restaurantName} helpdesk
              and also by informing about and offering the customer the
              possibility of objection when {restaurantName} is planning to
              implement changes to relevant practices and policies.
            </p>

            <p>
              The overall responsibility for data security lies with{" "}
              {restaurantName} Data Protection Officer who educates and updates
              all personnel on the data security measures outlined in{" "}
              {restaurantName} security handbook and this Terms and Conditions.
            </p>

            <h3>4.7. Monitoring</h3>
            <p>
              {restaurantName} uses security reports to monitor access patterns
              and to proactively identify and mitigate potential threats.
              Administrative operations, including system access, are logged to
              provide an audit trail if unauthorized or accidental changes are
              made.
            </p>

            <p>
              System performance and availability is monitored from both
              internal and external monitoring services.
            </p>

            <h3>4.8. Personal Data breach notification</h3>
            <p>
              In the event that your data is compromised, {restaurantName} will
              notify you and competent Supervisory Authority(ies) within 72
              hours by e-mail with information about the extent of the breach,
              affected data, any impact on the Service and {restaurantName}
              action plan for measures to secure the data and limit any possible
              detrimental effect on the data subjects.
            </p>

            <p>
              “Personal data breach” means a breach of security leading to the
              accidental or unlawful destruction, loss, alteration, unauthorized
              disclosure of, or access to, personal data transmitted, stored or
              otherwise processed in connection with the provision of the
              Service.
            </p>

            <h2>Do we disclose any information to outside parties?</h2>

            <p>
              {restaurantName} does not sell, trade or otherwise transfer to
              outside parties any personally identifiable information.
            </p>

            <p>
              This does not include trusted third parties or subcontractors who
              assist us in operating our website, conducting our business, or
              servicing you. Such trusted parties may have access to personally
              identifiable information on a need-to-know basis and will be
              contractually obliged to keep your information confidential.
            </p>

            <p>
              We may also release your information when we believe release is
              appropriate to comply with the law, enforce our site policies, or
              protect our or others’ rights, property, or safety. Furthermore,
              non-personally identifiable visitor information may be provided to
              other parties for marketing, advertising, or other uses.
            </p>

            <h3>1. Subcontractors/trusted third parties</h3>

            <p>
              {restaurantName} will monitor subcontractors’ and subprocessors’
              maintenance of these standards and audits to ensure that data
              protection requirements are fulfilled.
            </p>

            <p>
              Any intended changes concerning the addition or replacement of
              subcontractors or subprocessors handling personal data will be
              announced to you with at least 3 months’ notice. You retain at all
              times the possibility to object to such changes or to terminate
              the contract with {restaurantName}.
            </p>

            <h3>2. Legally required disclosure</h3>
            <p>
              {restaurantName} will not disclose the customer’s data to law
              enforcement except when instructed by you or where it is required
              by law. When governments make a lawful demand for customer data
              from {restaurantName}, {restaurantName} strives to limit the
              disclosure. {restaurantName} will only release specific data
              mandated by the relevant legal demand.
            </p>

            <p>
              If compelled to disclose your data, {restaurantName} will promptly
              notify you and provide a copy of the demand unless legally
              prohibited from doing so.
            </p>

            <h2>Third party links</h2>
            <p>
              Occasionally, at our discretion, we may include or offer third
              party products or services on our website. These third party sites
              have separate independent privacy policies. We therefore have no
              responsibility or liability for the content and activities of
              these linked websites. Nonetheless, we seek to protect the
              integrity of our website and welcome any feedback about these
              websites.
            </p>

            <h2>Where do we store the information?</h2>
            <p>
              No stored data will be transferred, backed up and/or recovered by
              {restaurantName} outside of the European Union.
            </p>

            <h3>1. Installation of software on cloud customer’s system</h3>
            <p>
              No installation of software is required to use the Service. The
              login-protected Service Manager is accessible through a standard
              web browser, automatically using an encrypted https-connection for
              all communications between your browser and {restaurantName}
              server to protect any data from being intercepted during network
              transfers.
            </p>

            <h2>
              Request for rectification, restriction or erasure of the personal
              data
            </h2>

            <h3>1. Rectification</h3>
            <p>
              You may at any time obtain without undue delay rectification of
              inaccurate personal data concerning you.
            </p>

            <h3>2. Restriction of processing personal data</h3>
            <p>
              You may at any time request {restaurantName} to restrict the
              processing of personal data when one of the following applies:
            </p>
            <ol className="paddingSpecial">
              <li>
                if you contest the accuracy of the personal data, for a period
                enabling {restaurantName} to verify the accuracy of the personal
                data;
              </li>

              <li>
                if the processing is unlawful and you oppose the erasure of the
                personal data and request the restriction of their use instead;
                or
              </li>

              <li>
                if {restaurantName} no longer needs the personal data for the
                purposes of the processing, but they are required by you for the
                establishment, exercise or defense of legal claims.
              </li>
            </ol>
            <h3>3. Erasure</h3>
            <p>
              You may without undue delay request the erasure of personal data
              concerning you, and {restaurantName} shall erase the personal data
              without undue delay when one of the following applies:
            </p>
            <ol className="paddingSpecial">
              <li>
                if the personal data are no longer necessary in relation to the
                purposes for which they were collected or otherwise processed;
              </li>

              <li>
                if you withdraw your consent on which the processing is based,
                and where there is no other legal ground for the processing;
              </li>

              <li>
                if you object to the processing in case the processing is for
                direct marketing purposes;
              </li>

              <li>if the personal data have been unlawfully processed; or</li>

              <li>
                if the personal data have to be erased for compliance with a
                legal obligation in EU or national law.
              </li>
            </ol>
            <h2>Data retention</h2>
            <h3>1. Data retention policy</h3>
            <p>
              Account Data will due to tax regulations be retained for up to
              five full fiscal years from your cancellation of your Service
              account.
            </p>

            <p>
              Configuration Data and System Generated Data will be erased
              immediately when you cancel the Service account.
            </p>

            <p>
              End User Data will be erased on an ongoing basis after 12 months
              from registration, and immediately when you cancel the Service
              account.
            </p>

            <h3>2. Data retention for compliance with legal requirements</h3>
            <p>
              You cannot require {restaurantName} to change any of the default
              retention periods, but may suggest changes for compliance with
              specific sector laws and regulations.
            </p>

            <h3>3. Data restitution and/or deletion</h3>
            <p>
              No data except Account Data will be retained after the termination
              of the contract. You may request a data copy before termination.
              You must not cancel the Service account until the data copy has
              been delivered, as {restaurantName} otherwise will not be able to
              deliver the data copy.
            </p>

            <h2>Accountability</h2>
            <p>
              {restaurantName} uses the extensive range of built-in logging
              features and audits trails provided by Microsoft on its Azure
              cloud platform. {restaurantName} also logs all system updates,
              configuration changes and access to provide an audit-trail if
              unauthorized or accidental changes are made.
            </p>

            <h2>Cooperation</h2>
            <p>
              {restaurantName} will cooperate with you in order to ensure
              compliance with applicable data protection provisions, e.g. to
              enable you to effectively guarantee the exercise of data subjects’
              rights (right of access, rectification, erasure, blocking,
              opposition), to manage incidents including forensic analysis in
              case of security breach.
            </p>

            <h2>Refund / Return Policy </h2>
            <p>
              This policy applies to walk-in and call-in customer/third-party
              delivery orders only.{" "}
            </p>
            <p>
              To provide the best customer satisfaction, we provide the
              following solutions. Feel free to contact us if you have any
              questions regarding the Return & Refund Policy by emailing us at
              the restaurant. For immediate response, please call the restaurant
              or your third-party delivery company. Preparation of your order
              can begin immediately after your order has been confirmed. We
              cannot accept cancellations once your order has been confirmed
              with the restaurant.{" "}
            </p>
            <h2>Food Order Errors* </h2>
            <p>
              If you receive food that is different from your receipt, we
              sincerely apologize. Please call us or your third-party delivery
              company as soon as you notice that there was an error in your
              order or contact your third-party delivery company. You may come
              to pick up the correct food item.{" "}
            </p>
            <p>
              For credit card payments, you will be refunded the sales price
              amount associated with the error and recharged for the new items
              price.{" "}
            </p>
            <p>
              For cash payments, you will be asked to pay the difference of the
              balance if the new food has a greater value than the food received
              in error. In the same way, you will receive the difference of the
              balance back as credit for the new item if less than the food
              received in error. In some cases, we may offer you a store credit.{" "}
            </p>
            <p>
              Your order will be priority if you come to pick it up. In all
              cases,please return the food order in the original container(s)*to
              our host.{" "}
            </p>
            <h2>Food Order Incomplete* </h2>
            <p>
              In the rare occasion that you do not receive food that is on your
              receipt, we will make it up to you. Please call us or your
              third-party delivery service as soon as you notice that any food
              items were not received in your order. You may cancel the missed
              food before we prepare it without any question, and we will refund
              the amount to a credit card or we will refund you with a store
              credit. No cash refunds.{" "}
            </p>
            <p>
              <b>Please note: </b> We do provide courtesy items such as
              disposable silverware, chopsticks, etc. when requested for free.
              If we mistakenly omit a free courtesy item you are welcome to let
              us know so we may address the issue with our staff, however, we
              cannot provide discounts, refunds or store credit.{" "}
            </p>
            <h2>Food Dissatisfaction*</h2>
            <p>
              We cook our food fresh to order with only the finest and freshest
              ingredients. We take great care and pride in all of the dishes we
              make. Please call us immediately if you receive unsatisfactory
              food caused by a dislike or objects in the food.We will need the
              food returned in the original container(s)*so we may investigate
              and deal with the issue. We will prepare you a new food order. If
              you do not wish to receive a new dish, we may refund the amount to
              a credit card or we will refund you with a store creditonly after
              we receive the food in the original container(s)*and have
              confirmed the error to the discretion of the manager on duty.{" "}
            </p>
            <h2>Cancel Order</h2>
            <p>
              Sorry, we cannot cancel, refund or give store credit if you change
              your mind or mistakenly order an item after the order is prepared.
              We cannot provide any refund or store credit for non-food items or
              beverages.{" "}
            </p>
            <h2>Order Cancelled Delivery Partner </h2>
            <p>
              It is possible your delivery partner might cancel the delivery if
              they are unable to find or reach you. When they arrive at your
              delivery address, they are prompted to contact you so it’s a good
              idea to keep your phone nearby when you’re expecting the food to
              arrive. If a delivery partner made a reasonable effort to contact
              you after arriving at your requested location, you may not be
              eligible for a refund.{" "}
            </p>
            <h2>Complimentary Food </h2>
            <p>
              Sorry, we cannot provide a refund or cash value on any
              complimentary food.{" "}
            </p>
            <p>
              As a private business, it is the right of the restaurant to deny
              or refuse service to any customer for any reason. If a customer
              abuses any of the Return & Refund Policy we, unfortunately, will
              not be able to conduct future business with the customer so that
              we may continue to provide excellent and quality food and service
              for you and our other wonderful and frequent patrons.{" "}
            </p>
            <p>
              <b>Please note:</b>We strive to prepare and package our pick up
              items to preserve the high quality of the food. Keep in mind the
              temperature, sauce, and consistency of some items may vary
              slightly after being packaged.
            </p>
          </Col>

          <col className="whitespace"></col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
