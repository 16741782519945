import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BarTop from "../../BarTop";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useHistory, useLocation } from "react-router-dom";
import FontSizeChanger from "react-font-size-changer";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import { FormProvider, useForm } from "react-hook-form";
import { CircularProgress, Stack } from "@mui/material";
import { Input } from "components";
import { resolver } from "utils/helpers";
import * as Yup from "yup";
import moment from "moment";
import packageJson from "../../../../../../package.json";
import BarBottom from "../../BarBottom";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
const loginCustomerNumber = process.env.REACT_APP_LOGIN_CUSTOMER_NUMBER;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
let enableNewUI = process.env.REACT_APP_NEWUI_LW;

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "360px",
  backgroundColor: "transparent !important",
  boxShadow: "none !important",
  textTransform: "none",
});

const theme = createTheme();

const requiredMessage = "This field is required.";
const schema = {
  email: Yup.string()
    .email("Please enter a valid email")
    .required(requiredMessage),
};

export const LogInSL = () => {
  const history = useHistory();

  const [rememberMe, setRememberMe] = useState(true);
  const [newFontSize, setFontSize] = useState(16);
  const increaseFont = () => setFontSize(newFontSize + 2);

  const [cookie, setCookie, removeCookie] = useCookies([
    "firstName",
    "lastName",
    "roomNumber",
    "pinCodeOk",
    "login_exp",
    "CreditLimit",
    "AvailableCredit",
    "MealCount",
  ]);

  const { state } = useLocation();

  const [disabled, setDisabled] = useState(false);

  const formMethods = useForm({
    resolver: resolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const validate = useCallback(async () => {
    const pinCodeOk = cookie["pinCodeOk"];

    if (!pinCodeOk && pinCodeOk !== 1) return;
    const loginExp = cookie["login_exp"];
    let selectedDate = moment(loginExp);
    let currentDate = moment();
    if (loginExp && selectedDate.isBefore(currentDate)) return;
    history.push("/welcome");
  }, [history, cookie]);

  useEffect(() => {
    validate();
  }, [validate]);

  // Get only important methods
  const { handleSubmit } = formMethods;

  const onSubmit = async (formData) => {
    setDisabled(true);
    try {
      // Change API endpoint to logged in
      const { data } = await axios.get(
        `https://ecommv2.servingintel.com/customer/get-by-email/${serverId}/${siteId}/${formData.email}`
      );

      if (data === 0) {
        alertify.alert("Error", "Invalid credentials", function () {});
        setDisabled(false);
        return;
      }
      const customer = data;

      setCookie(
        "roomNumber",
        loginCustomerNumber === "1"
          ? customer.CustomerNumber
          : customer.Address1
      );
      setCookie("firstName", customer.FirstName, { path: "/" });
      setCookie("lastName", customer.LastName, { path: "/" });
      setCookie("roomNumber", customer.Address1, { path: "/" });
      setCookie("CreditLimit", customer.CreditLimit, { path: "/" });
      setCookie("AvailableCredit", customer.AvailableCredit, { path: "/" });
      setCookie("MealCount", customer.MealCount, { path: "/" });

      const people = [];
      people.push({
        firstname: customer.FirstName,
        room: customer.Address1,
        custid: customer.CustomerID,
        lastname: customer.LastName,
        creditlimit: customer.AvailableCredit,
        availablecredit: customer.CreditLimit,
        mealcount: customer.MealCount,
      });

      if (cookie.customerList !== undefined) {
        const addPeople = [...cookie.customerList, ...people];
        const output = [
          ...new Map(addPeople.map((o) => [o.custid, o])).values(),
        ];
        setCookie("customerList", output, { path: "/" });
      } else {
        setCookie("customerList", people, { path: "/" });
      }
      setCookie("pinCodeOk", 1);

      history.push("/welcome");
      // if (customer.SIC_PIN) {
      //   history.push("/LoginCode");
      // } else {
      //   history.push("/UpdateCode");
      // }

      // sessionStorage.setItem(
      //   "roomNumber",
      //   loginCustomerNumber === "1"
      //     ? customer.CustomerNumber
      //     : customer.Address1
      // );
      // setCookie("firstName", customer.FirstName, { path: "/" });
      // setCookie("roomNumber", customer.Address1, { path: "/" });

      // const people = [];
      // people.push({
      //   firstname: customer.FirstName,
      //   room: customer.Address1,
      //   custid: customer.CustomerID,
      //   lastname: customer.LastName,
      // });

      // if (cookie.customerList !== undefined) {
      //   const addPeople = [...cookie.customerList, ...people];
      //   const output = [
      //     ...new Map(addPeople.map((o) => [o.custid, o])).values(),
      //   ];
      //   setCookie("customerList", output, { path: "/" });
      // } else {
      //   setCookie("customerList", people, { path: "/" });
      // }
      // navigate(from, { replace: true });
    } catch (error) {
      alertify.alert("Error", "Invalid credentials", function () {});
    } finally {
      setDisabled(false);
    }
  };

  const onClickRemember = () => {
    setCookie("login_exp", moment().add(24, "hours").format());
  };

  const RenderLabel = ({ label }) => {
    return (
      <Typography
        sx={{
          marginBottom: 2,
        }}
      >
        {label}{" "}
        <Typography component="span" color="#f30c0c">
          *
        </Typography>
      </Typography>
    );
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid
          container
          component="main"
          sx={{ fontSize: `${newFontSize}px`, height: "100vh" }}
        >
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "left",
              left: "0",
              right: "0",
              backgroundImage: `url(${siteMedSrc}signbg.png)`,
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              id="target"
              sx={{ my: 8, mx: 4, display: "flex", flexDirection: "column" }}
            >
              {enableNewUI == 1 ? (
                <img
                  src={siteMedSrc + "logo" + ".png"}
                  className="SL-logo02"
                  alt="logo"
                />
              ) : enableNewUI == 0 ? (
                <img
                  src={siteMedSrc + "logo" + ".png"}
                  className="SL-logo"
                  alt="logo"
                />
              ) : (
                // Add a default case here, for example:
                <img
                  src={siteMedSrc + "logo" + ".png"}
                  className="Default-logo"
                  alt="logo"
                />
              )}

              <Typography
                className="content"
                sx={{ mt: -1, textAlign: "center" }}
                component="h4"
                variant="h4"
              >
                <br />
                Hello. Please log into your account.
              </Typography>

              <FormProvider {...formMethods}>
                <Box sx={{ mt: 1 }}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack gap={3}>
                      <Input
                        variant="standard"
                        name="email"
                        placeholder=""
                        autoComplete="off"
                        disabled={disabled}
                        label={<RenderLabel label="Enter your email address" />}
                      />

                      {disabled ? (
                        <Button
                          fullWidth
                          disabled
                          type="button"
                          sx={{
                            display: "flex",
                            gap: 2,
                            background: "gray",
                          }}
                        >
                          <CircularProgress color="inherit" /> <span>Send</span>
                        </Button>
                      ) : (
                        <>
                          <Button
                            type="submit"
                            size="large"
                            fullWidth
                            variant="contained"
                            sx={{ fontWeight: "600" }}
                            onClick={(e) => console.log(e, "log")}
                          >
                            <Typography
                              component="span"
                              sx={{
                                color: "white!important",
                                fontWeight: "600",
                                fontSize: {
                                  xs: ".8rem!important",
                                  lg: "1.3rem!important",
                                },
                              }}
                            >
                              Login and remember me on this device
                            </Typography>
                          </Button>
                          <button
                            className="MuiButton-root"
                            style={{
                              borderColor: "#fff",
                              padding: "13px",
                              width: "100%",
                              border: "2px solid #0a4875",
                              background: "transparent",
                              display: "flex",
                              color: "#cea052!important",
                              alignItems: "center",
                              textAlign: "center",
                              lineHeight: 1.15,
                              letterSpacing: "0.00938em",
                            }}
                          >
                            <Typography
                              component="span"
                              // className="content gold"
                              sx={{
                                fontWeight: "600",
                                width: "100%",
                                color: "#0a4875!important",
                                textTransform: "uppercase",
                                fontSize: {
                                  xs: ".8rem!important",
                                  lg: "1.3rem!important",
                                },
                              }}
                              onClick={onClickRemember}
                            >
                              Login and do not remember this device
                            </Typography>
                          </button>
                        </>
                      )}
                    </Stack>
                  </form>
                </Box>
              </FormProvider>
            </Box>

            <FontSizeChanger
              targets={["#target .content"]}
              onChange={(element, newValue, oldValue) => {
                console.log(element, newValue, oldValue);
              }}
              options={{
                stepSize: 2,
                range: 5,
              }}
              customButtons={{
                up: (
                  <AppBar
                    position="fixed"
                    sx={{
                      width: "90px",
                      boxShadow: 0,
                      top: "auto",
                      bottom: 237.5,
                      backgroundColor: "transparent",
                    }}
                  >
                    <Toolbar>
                      <Box sx={{ flexGrow: 1 }} />
                      <IconButton aria-label="add font size">
                        <Fab
                          color="primary"
                          aria-hidden="true"
                          sx={{ backgroundColor: "#3D5309" }}
                        >
                          <AddCircleOutlineIcon />
                        </Fab>
                      </IconButton>
                    </Toolbar>
                  </AppBar>
                ),
                down: (
                  <AppBar
                    position="fixed"
                    sx={{
                      width: "90px",
                      boxShadow: 0,
                      top: "auto",
                      bottom: 180,
                      backgroundColor: "transparent",
                    }}
                  >
                    <Toolbar>
                      <Box sx={{ flexGrow: 1 }} />
                      <IconButton>
                        <Fab
                          color="primary"
                          aria-hidden="true"
                          sx={{ backgroundColor: "#765F00" }}
                        >
                          <RemoveCircleOutlineIcon />
                        </Fab>
                      </IconButton>
                    </Toolbar>
                  </AppBar>
                ),
              }}
            />
          </Grid>
        </Grid>
        <BarTop />
        <BarBottom />
      </ThemeProvider>
    </>
  );
};
