import "../../../App.css";
import { Container, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { withRouter, useHistory, useParams } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AccountForm from "./components/AccountForm";
import MobileForm from "./components/MobileForm";
import PersonalDetails from "./components/PersonalDetails";
import { FaHeart } from "react-icons/fa";
import axios from "axios";
import Swal from "sweetalert2";
import { Helmet, HelmetProvider } from "react-helmet-async";
import CookieConsent from "react-cookie-consent";
import { useCookies } from "react-cookie";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const buttonColor = process.env.REACT_APP_BUTTON_COLOR;

export const ChangePassword = () => {
  function Copyright(props) {
    return (
      <Typography variant="body2" color="white" align="center" {...props}>
        Made with <FaHeart style={{ color: "red" }} /> by <br />
        <Link color="inherit" href="https://servingintel.com">
          <img
            className="Logo"
            style={{ padding: "10px 0", width: "150px" }}
            src={siteMedSrc + "servingintel-logo-bw" + ".svg"}
            alt="ServingIntel Logo"
            width="100%"
            height="100%"
            title="ServingIntel Logo"
            loading="lazy"
          />
        </Link>
      </Typography>
    );
  }

  const [cookieSIC, setCookieSIC, removeCookieSIC] = useCookies([
    "sic_email",
    "sic_user_id",
  ]);

  const history = useHistory();
  const theme = createTheme();
  // const [data, setData] = useState([]);
  const { id } = useParams();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const handleUpdate = async () => {
    if (newPassword !== confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "Sorry...",
        text: "Your new password and confirmed password do not match",
      });
      return;
    }

    if (newPassword.includes(" ")) {
      Swal.fire({
        icon: "error",
        title: "Sorry...",
        text: "Please set a new password that does not contain any spaces or blank characters",
      });
      return;
    }

    if (
      !currentPassword.trim() ||
      !newPassword.trim() ||
      !confirmPassword.trim()
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please provide all the required information",
      });
      return;
    }

    try {
      // SERVER PROXY FOR CORS PREFLIGHT ERROR
      const proxy = "https://web-production-d6e6.up.railway.app/";
      const endpoint =
        proxy +
        "https://ecommv3.servingintel.com/customer/update-profile-password-by-email/" +
        serverId +
        "/" +
        siteId +
        "/" +
        cookieSIC.sic_email;
      const res = await axios.post(
        proxy +
          "https://ecommv3.servingintel.com/customer/update-profile-password-by-email/" +
          serverId +
          "/" +
          siteId +
          "/" +
          cookieSIC.sic_email,
        { OldPassword: currentPassword, NewPassword: newPassword }
      );
      // console.log(res.data.status);
      if (res.data.status === 200) {
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setError("");
        Swal.fire({
          icon: "success",
          title: "Password has been updated!",
          text: "You'll be redirected to the home page",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          // Redirect to home page
          setTimeout(() => {
            history.push("/");
          }, 1500);
        });
      } else {
        if (currentPassword === newPassword) {
          Swal.fire({
            icon: "error",
            title: "Sorry...",
            text: "Your new password must be different from your current password",
          });
          return;
        }
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "You entered an incorrect old password",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
    } catch (error) {
      console.error("Failed due to: ", error);
      console.log("Current password is incorrect");
    }
  };

  // const checkPassword = async () => {
  //   const proxy = "https://cors-anywhere.herokuapp.com/"
  //   const endpoint = proxy + "https://ecommv3.servingintel.com/customer/update-profile-password-by-email/"+
  //       serverId +
  //       "/" +
  //       siteId +
  //       "/" +
  //       cookieSIC.sic_email;

  //   const response = await axios.post(endpoint, {
  //     Password: currentPassword,
  //   });

  //   if (response.status === 200) {
  //     console.log("old password is correct" + currentPassword)
  //     // The old password is correct.
  //     return true;
  //   } else {
  //     console.log("old password is incorrect")
  //     // The old password is incorrect.
  //     return false;
  //   }
  // }

  const paperStyle = {
    padding: 20,
    height: "auto",
    width: 320,
    margin: "20px auto",
  };
  const avatarStyle = { backgroundColor: "#1bbd7e" };
  const btnstyle = { margin: "4px 0" };

  return (
    <div className="customBg">
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{"Change Password"}</title>
            <link rel="canonical" href="/change-password" />
          </Helmet>
        </HelmetProvider>
        <Container
          component="main"
          className="eLoyaltyBg"
          maxWidth="md"
          style={{ maxWidth: "850px", padding: "0", marginTop: "-50px" }}
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "5px 20px",
              borderRadius: "5px",
            }}
          >
            <a href="/" target="_self">
              <img
                className="Logo title"
                style={{ padding: "10px 0", width: "150px" }}
                src={siteMedSrc + "logo" + ".png"}
                width="100%"
                height="100%"
                title={restaurantName}
                alt={restaurantName}
                loading="lazy"
              />
            </a>
            <Typography
              component="h1"
              className="white"
              variant="h4"
              style={{ marginBottom: "10px", fontWeight: "550" }}
            >
              Change password
            </Typography>
            <div className="col-lg-12">
              <div className="white">
                <h3>Enter a new password below to change your password</h3>
              </div>
            </div>

            <Paper
              style={{
                boxShadow: "none",
                marginTop: "0",
                backgroundColor: "transparent",
                marginBottom: "1rem",
                width: "100%",
              }}
            >
              <React.Fragment>
                <Container className="formBG" style={{ padding: "0" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    variant="outlined"
                    padding="0"
                  >
                    <TextField
                      value={currentPassword}
                      margin="dense"
                      required
                      fullWidth
                      type={"password"}
                      id="changePassword"
                      label="Current password"
                      name="changePassword"
                      autoComplete="true"
                      onChange={(e) => {
                        setCurrentPassword(e.target.value);
                      }}
                    />
                    <TextField
                      value={newPassword}
                      margin="dense"
                      required
                      fullWidth
                      type={"password"}
                      id="newPassword"
                      label="New password"
                      name="newPassword"
                      autoComplete="true"
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                      }}
                    />
                    <TextField
                      value={confirmPassword}
                      margin="dense"
                      required
                      fullWidth
                      type={"password"}
                      id="newPasswordConfirm"
                      label="Re-type new password"
                      name="newPasswordConfirm"
                      autoComplete="true"
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                    />
                    <Button
                      onClick={handleUpdate}
                      variant="contained"
                      fullWidth
                      sx={{ mt: 3, ml: 1, fontWeight: "550" }}
                    >
                      Submit new password
                    </Button>
                    <Button
                      fullWidth
                      onClick={() => {
                        history.push("/");
                      }}
                      size="large"
                      variant="contained"
                      style={{
                        fontWeight: "550",
                        backgroundColor: buttonColor,
                        borderColor: "none",
                        margin: ".5rem 0",
                      }}
                    >
                      Cancel
                    </Button>

                    {/* <Button
                            onClick={checkPassword}
                      >
                        Check Password
                      </Button> */}
                  </Box>
                </Container>
              </React.Fragment>
            </Paper>
          </Box>
          <Copyright />
        </Container>
        <CookieConsent
          location="bottom"
          buttonText="Accept all cookies"
          cookieName="myAwesomeCookieName2"
          buttonClasses="btn btn-red"
          style={{ background: "#000" }}
          buttonStyle={{ fontSize: "16px" }}
          expires={365}
        >
          <div className="center">
            <Col xs={3} className="padding-bottom-20 center">
              <img
                width="100%"
                height="100%"
                title="Accept all cookies Icon"
                src={siteMedSrc + "cookie-icon" + ".svg"}
                size="15"
                alt="Accept all cookies Icon"
                loading="lazy"
              />
            </Col>
            <p>
              <h3 className="center"> Your Privacy </h3>
            </p>
          </div>

          <Typography>
            Greetings! Our website uses cookies so we may better serve you. By
            clicking “Accept all cookies” and by continuing to browse our site
            you are agreeing to our{" "}
          </Typography>
          <Typography>
            <a
              href="#"
              onClick={() => {
                history.push("/terms-and-conditions");
              }}
            >
              Terms and Conditions
            </a>
          </Typography>
        </CookieConsent>
      </ThemeProvider>
    </div>
  );
};

export default withRouter(ChangePassword);
