import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function ImgListCard() {
  return (
    <ImageList
      sx={{ width: 500, height: 450 }}
      variant="quilted"
      cols={4}
      rowHeight={121}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
          <img
            {...srcset(item.img, 121, item.rows, item.cols)}
            alt={item.alt}
            loading="lazy"
            title={item.title}
            width="100%"
            height="100%"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: `${siteMedSrc}A.jpg`,
    title: `${restaurantName} Image-A`,
    rows: 2,
    cols: 2,
    alt: `${restaurantName} Image-A`,
  },
  {
    img: `${siteMedSrc}B.jpg`,
    title: `${restaurantName} Image-B`,
    alt: `${restaurantName} Image-B`,
  },
  {
    img: `${siteMedSrc}C.jpg`,
    title: `${restaurantName} Image-C`,
    alt: `${restaurantName} Image-C`,
  },
  {
    img: `${siteMedSrc}D.jpg`,
    title: `${restaurantName} Image-D`,
    cols: 2,
    alt: `${restaurantName} Image-D`,
  },
  {
    img: `${siteMedSrc}E.jpg`,
    title: `${restaurantName} Image-E`,
    cols: 2,
    alt: `${restaurantName} Image-E`,
  },
  {
    img: `${siteMedSrc}F.jpg`,
    title: `${restaurantName} Image-F`,
    rows: 2,
    cols: 2,
    alt: `${restaurantName} Image-F`,
  },
  {
    img: `${siteMedSrc}G.jpg`,
    title: `${restaurantName} Image-G`,
    alt: `${restaurantName} Image-G`,
  },
  {
    img: `${siteMedSrc}H.jpg`,
    title: `${restaurantName} Image-H`,
    alt: `${restaurantName} Image-H`,
  },
  {
    img: `${siteMedSrc}I.jpg`,
    title: `${restaurantName} Image-I`,
    rows: 2,
    cols: 2,
    alt: `${restaurantName} Image-I`,
  },
  {
    img: `${siteMedSrc}J.jpg`,
    title: `${restaurantName} Image-J`,
    alt: `${restaurantName} Image-J`,
  },
  {
    img: `${siteMedSrc}K.jpg`,
    title: `${restaurantName} Image-K`,
    alt: `${restaurantName} Image-K`,
  },
  {
    img: `${siteMedSrc}L.jpg`,
    title: `${restaurantName} Image-L`,
    cols: 2,
    alt: `${restaurantName} Image-L`,
  },
  {
    img: `${siteMedSrc}M.jpg`,
    title: `${restaurantName} Image-M`,
    cols: 2,
    alt: `${restaurantName} Image-M`,
  },
  {
    img: `${siteMedSrc}N.jpg`,
    title: `${restaurantName} Image-N`,
    rows: 2,
    cols: 2,
    alt: `${restaurantName} Image-N`,
  },
  {
    img: `${siteMedSrc}O.jpg`,
    title: `${restaurantName} Image-O`,
    alt: `${restaurantName} Image-O`,
  },
  {
    img: `${siteMedSrc}P.jpg`,
    title: `${restaurantName} Image-P`,
    alt: `${restaurantName} Image-P`,
  },
  {
    img: `${siteMedSrc}Q.jpg`,
    title: `${restaurantName} Image-Q`,
    rows: 2,
    cols: 2,
    alt: `${restaurantName} Image-Q`,
  },
  {
    img: `${siteMedSrc}R.jpg`,
    title: `${restaurantName} Image-R`,
    alt: `${restaurantName} Image-R`,
  },
  {
    img: `${siteMedSrc}S.jpg`,
    title: `${restaurantName} Image-S`,
    alt: `${restaurantName} Image-S`,
  },
  {
    img: `${siteMedSrc}T.jpg`,
    title: `${restaurantName} Image-T`,
    cols: 2,
    alt: `${restaurantName} Image-T`,
  },
];