import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function PhotoGalleryList() {
  return (
    <ImageList
      // sx={{ width: 500, height: 450 }}
      wrapperClass="wrapper"
      gridClass="col-md-4"
      variant="quilted"
      imgClass="responsive"
      cols={4}
    // rowHeight={150}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
          <img
            {...srcset(item.img, item.rows, item.cols)}
            alt={item.title}
            loading="lazy"
            title={item.title}
            width="100%"
            height="100%"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

const itemData = [
  {
    img: `${siteMedSrc}1.jpg`,
    title: `${restaurantName} Image-01`,
  },
  {
    img: `${siteMedSrc}2.jpg`,
    title: `${restaurantName} Image-02`,
  },
  {
    img: `${siteMedSrc}3.jpg`,
    title: `${restaurantName} Image-03`,
  },
  {
    img: `${siteMedSrc}4.jpg`,
    title: `${restaurantName} Image-04`,
  },
  {
    img: `${siteMedSrc}5.jpg`,
    title: `${restaurantName} Image-05`,
  },
  {
    img: `${siteMedSrc}6.jpg`,
    title: `${restaurantName} Image-06`,
  },
  {
    img: `${siteMedSrc}7.jpg`,
    title: `${restaurantName} Image-07`,
  },
  {
    img: `${siteMedSrc}8.jpg`,
    title: `${restaurantName} Image-08`,
  },
  {
    img: `${siteMedSrc}9.jpg`,
    title: `${restaurantName} Image-09`,
  },
  {
    img: `${siteMedSrc}10.jpg`,
    title: `${restaurantName} Image-10`,
  },
  {
    img: `${siteMedSrc}11.jpg`,
    title: `${restaurantName} Image-11`,
  },
  {
    img: `${siteMedSrc}12.jpg`,
    title: `${restaurantName} Image-12`,
  },
  {
    img: `${siteMedSrc}13.jpg`,
    title: `${restaurantName} Image-13`,
  },
  {
    img: `${siteMedSrc}14.jpg`,
    title: `${restaurantName} Image-14`,
  },
  {
    img: `${siteMedSrc}15.jpg`,
    title: `${restaurantName} Image-15`,
  },
  {
    img: `${siteMedSrc}16.jpg`,
    title: `${restaurantName} Image-16`,
  },
  {
    img: `${siteMedSrc}17.jpg`,
    title: `${restaurantName} Image-17`,
  },
  {
    img: `${siteMedSrc}18.jpg`,
    title: `${restaurantName} Image-18`,
  },
  {
    img: `${siteMedSrc}19.jpg`,
    title: `${restaurantName} Image-19`,
  },
  {
    img: `${siteMedSrc}20.jpg`,
    title: `${restaurantName} Image-20`,
  },
];
