import React from 'react';

const addressMap = process.env.REACT_APP_ADDRESS_MAP;

export const AddressMap = () => {
  return (
    <div className="google-map-code">
      <iframe src={addressMap} width="100%" height="450" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    </div>
  );
}